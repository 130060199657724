import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Hermeneutica este știința și arta de a studia Biblia! Metoda inductivă, prezentată de acest curs te va ajuta să studiezi fiecare unitatea a scripturii (pasaj, capitol sau carte) pentru a găsi corect conținutul, înțelesul și aplicația.`}</MDXTag>
      <MDXTag name="p" components={components}>{`După ce te vei deprinde să privești pasajul în contextul biblic, literar, istoric-cultural și geografic; după ce prin rugăciune și reflectare vei găsi ideea centrală a textului biblic și vei fi sigur că Dumnezeu ți-a vorbit personal; după ce vei parcurge acest curs, studiul Bibliei va deveni o plăcere… vei avea cheițele cele mai importante cu care vei trece dincolo de presupuneri și prejudecăți.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Scopul acestui curs nu este pregătirea predicii, ci studierea cu scopul de a aplica în viața personală, dar este o unealtă strict necesară pentru cei care sunt chemați să predice sau să învețe din Scripturi.
Citat: „Scopul suprem este cunoașterea lui Dumnezeu. Dar calea principală prin care Îl cunoaștem este Cuvântul Lui… Da, studiem Biblia, dar nu ne oprim la Biblie, ci înțelegem că prin Biblie ajungem la Dumnezeu.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    